






























import { Component, Vue } from 'vue-property-decorator';

import SupportWidgetErrorViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-error-view-model';

@Component({ name: 'SupportWidgetError' })
export default class SupportWidgetError extends Vue {
  support_widget_error_view_model = Vue.observable(
    new SupportWidgetErrorViewModel(this),
  );
}
